<div class="container">
  <div class="row">
    <div class="col">
      <div class="container mt-5">
        <h1>Login to GRP</h1>
        <p>
          Some info about international programs, and how to register or handle
          login issue
        </p>
      </div>
    </div>

    <div class="col">
      <div class="container mt-5">
        <button type="button" (click)="login()" mat-flat-button color="primary">Login</button>
      </div>
    </div>
  </div>
</div>