import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ApprovalAuthorizationRequest } from 'src/app/shared/models/approval-authorization-request.model';
import { Authorization } from 'src/app/shared/models/authorization.model';

@Component({
  selector: 'app-reverse-approval-modal',
  templateUrl: './reverse-approval-modal.component.html',
  styleUrls: ['./reverse-approval-modal.component.scss']
})
export class ReverseApprovalModalComponent implements OnInit {
  @Output()
  public close = new EventEmitter();
  public authorization: Authorization;

  authorizationRequest = new ApprovalAuthorizationRequest();
  authNumber: string;
  hideReverseApproval: boolean = true;

  constructor(private readonly apiService: ApiService,
    public dialogRef: MatDialogRef<ReverseApprovalModalComponent>) { }

  ngOnInit(): void {
    this.authorizationRequest.authorizationCode = this.authorization?.authorizationCode;
  }

  reverseApproval(): void {
    this.apiService.reverseApproval(this.authorizationRequest)
    .subscribe((resp: Authorization) => {
      this.authorization = resp;
      this.hideReverseApproval = false;

      this.close.emit({
        returnAuthorization: this.authorization,
        hideReverseApproval: this.hideReverseApproval
      });
      this.dialogRef.close();
    });
  }

  returnToConfirmation(): void {
    this.dialogRef.close();
  }

}
