import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { User } from '../models/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  userSubscription: Subscription;
  user: User;

  constructor(
    private readonly api: ApiService,
    public router: Router,
    private readonly userService: UserService
  ) {
    this.userSubscription = this.userService.user$.subscribe((user: User) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
  }

  logout() {
    this.api.logout().subscribe((data: any) => {
      this.user = null as any;
      this.router.navigate(['/login']);
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
