import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModules } from './material-modules';
import { ConfirmationComponent } from './redemption/confirmation/confirmation.component';
import { RedemptionComponent } from './redemption/redemption.component';
import { ApprovalComponent } from './approval/approval.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptor } from './services/http.interceptor.service';
import { ApiService } from './services/api.service';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { EnterCodeComponent } from './redemption/enter-code/enter-code.component';
import { AuthApproveComponent } from './redemption/auth-approve/auth-approve.component';
import { ReverseApprovalModalComponent } from './redemption/reverse-approval-modal/reverse-approval-modal.component';
import { CommonModule } from '@angular/common';
import { LoginReturnComponent } from './login-return/login-return.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginReturnComponent,
    ConfirmationComponent,
    RedemptionComponent,
    ApprovalComponent,
    HeaderComponent,
    EnterCodeComponent,
    AuthApproveComponent,
    ReverseApprovalModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModules,
    HttpClientModule,
    CommonModule,
    RouterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    ApiService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
