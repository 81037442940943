import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from 'src/app/authorization.service';
import { ApiService } from 'src/app/services/api.service';
import { Authorization } from 'src/app/shared/models/authorization.model';

@Component({
  selector: 'app-enter-code',
  templateUrl: './enter-code.component.html',
  styleUrls: ['./enter-code.component.scss'],
})
export class EnterCodeComponent implements OnInit {
  submitted = false;
  errorCode: string;
  error: boolean;
  loading: boolean;
  @Output() confirmation = new EventEmitter<boolean>();
  @Output() validation = new EventEmitter<boolean>();

  authCodeForm = new UntypedFormGroup({
    authCode: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^-?(0|[1-9]\d*)?$/), // numerics only
    ]),
  });

  constructor(
    private readonly api: ApiService,
    private readonly authService: AuthorizationService
  ) { }

  ngOnInit(): void { }

  onSubmit(): void {
    this.error = false;
    this.submitted = true;
    this.loading = true;
    if (this.authCodeForm.valid) {
      this.api
        .verifyAuthCode(this.authCodeForm.get('authCode')?.value)
        .subscribe((res: Authorization) => {
          this.authService.setAuthorization(res);
          this.loading = false;
          this.checkForNewValidInfo(res.approvalCode);
        }, error => {
          this.loading = false;
          this.error = true;
        });
    }
  }

  checkForNewValidInfo(approvalCode: string): void {
    approvalCode !== "" ? this.confirmation.emit(): this.validation.emit()
  }
}
