import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from './services/user.service';
import { User } from './models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  implements OnDestroy {
  userSubscription: Subscription;
  isLoggedIn: boolean = false;

  constructor(private readonly router: Router, private readonly userService: UserService) {
    this.userSubscription = this.userService.user$.subscribe((user: User) => {
      this.isLoggedIn = user ? true : false;
    });
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!!this.isLoggedIn) {
      return true;
    }else{
      this.router.navigate(['login']);
      return false;
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
