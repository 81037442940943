import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ApprovalAuthorizationRequest } from '../shared/models/approval-authorization-request.model';
import { Authorization } from '../shared/models/authorization.model';
import { ApplicationMetaData } from '../models/application.metadata';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private readonly http: HttpClient) { }

  getApplicationMetaData(): Observable<ApplicationMetaData> {
    return this.http.get<ApplicationMetaData>('metadata');
  }

  login(userToken: string): Observable<any> {
    const input: LoginRequest = {
      userToken: userToken
    }
    return this.http.post('login', input);
  }

  logout(): Observable<any> {
    return this.http.get('logout');
  }

  postApproval(authorization: ApprovalAuthorizationRequest): Observable<any> {
    return this.http.post(`approval`, authorization);
  }

  verifyAuthCode(authCode: string): Observable<any> {
    return this.http.get(`approval/validate/${authCode}`);
  }

  reverseApproval(request: ApprovalAuthorizationRequest): Observable<any> {
    return this.http.put('approval/reverse',request);
  }
}

export class LoginRequest {
  userToken: string | undefined;
}
