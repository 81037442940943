
    <div class="row">
      @if (this.hideReverseApproval) {
        <div class="col" id="page-styling">
          <p>Approval Code:</p>
        </div>
        <div class="col">
          <p>{{authorization.approvalCode}}</p>
        </div>
      }
    </div>

    <div class="row">
      @if (this.hideReverseApproval) {
        <div class="col" id="page-styling">
          <p>Approval Date:</p>
        </div>
        <div class="col">
          <p>{{authorization.approvalDate | date: 'MM/dd/yyyy'}}</p>
        </div>
      }
    </div>

    <div class="row">
      <div class="col" id="page-styling">
        <p>Status:</p>
      </div>
      <div class="col">
        <p>{{authorization.authorizationStatus}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col" id="page-styling">
        <p>Authorization Code:</p>
      </div>
      <div class="col">
        <p>{{authorization.authorizationCode}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col" id="page-styling">
        <p>Authorization Issue Date:</p>
      </div>
      <div class="col">
        <p>{{authorization.issueDate | date: 'MM/dd/yyyy'}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col" id="page-styling">
        <p>Authorization Expiration Date:</p>
      </div>
      <div class="col">
        <p>{{authorization.expirationDate | date: 'MM/dd/yyyy'}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col" id="page-styling">
        <p>Authorization Amount:</p>
      </div>
      <div class="col">
        <p>{{authorization.authorizationAmount}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col" id="page-styling">
        <p>First Name:</p>
      </div>
      <div class="col">
        <p>{{authorization.firstName}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col" id="page-styling">
        <p>Last Name:</p>
      </div>
      <div class="col">
        <p>{{authorization.lastName}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col" id="page-styling">
        <p>Postal Code:</p>
      </div>
      <div class="col">
        <p>{{authorization.postalCode}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col" id="page-styling">
        <p>Campaign Name:</p>
      </div>
      <div class="col">
        <p>{{authorization.campaignName}}</p>
      </div>
    </div>

<div class="row" id="button-styling">
  <div class="col text-center">
    <button mat-flat-button color="primary" (click)="printConfirmation()">Export to PDF</button>
  </div>
  <div class="col text-center">
    <button mat-flat-button color="primary" (click)="onValidateAnother()">Validate Another Auth</button>
  </div>
  <div class="col text-center">
    @if (this.hideReverseApproval) {
      <button mat-flat-button color="primary" (click)="onReverseApproval(authorization.authorizationCode)">Reverse Approval</button>
    }
  </div>
</div>

