import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  private readonly userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null as any);
  private user: User;

  constructor() { }

  get user$(): Observable<User> {
    return this.userSubject.asObservable();
  }

  setUser(user: User): void {
    this.user = user;
    this.userSubject.next(user);
  }

  getUser(): Observable<User> {
    return this.userSubject.asObservable();
  }

  ngOnDestroy(): void {
    this.userSubject.unsubscribe();
  }
}
