import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login-return',
  templateUrl: './login-return.component.html',
  styleUrls: ['./login-return.component.scss']
})
export class LoginReturnComponent implements OnInit {
  showSpiner: boolean = false;
  loginError: string = '';
  constructor(private readonly authService: AuthenticationService,
    private readonly userService: UserService,
    private readonly _router: Router,
    private readonly route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.showSpiner = true;
      this.authService.loginReturn(params.code).subscribe((user: User) => {
        if (user.accountId) {
          this.userService.setUser(user);
          this._router.navigate(['redemption']);
        }
      }, error => {
        this.showSpiner = false;
        this.loginError = getError(error);
      });
    });
  }
}

function getError(error: any) {
  const message = error.error.errors[0].message;
  switch (message) {
    case "corporateUserIsNotWhitelisted":
      return "Corporate User Is Not Whitelisted"
      break;
    case "oAuthInvalidGrant":
      return"Invalid Grant"
      break;
    default:
      return "Something Went Wrong"
      break;
  }
}
