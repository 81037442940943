import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Authorization } from '../shared/models/authorization.model';

@Component({
  selector: 'app-redemption',
  templateUrl: './redemption.component.html',
  styleUrls: ['./redemption.component.scss'],
})

export class RedemptionComponent implements OnInit {
  validInfo: Authorization;
  redemptionMode = '';

  constructor(public router: Router) { }

  ngOnInit(): void {
    this.redemptionMode = RedemptionMode.EnterCode;
  }

  updateRedemptionMode(modeDestination: RedemptionMode): void {
    this.redemptionMode = modeDestination;
  }

  goToEnterCode() {
    this.redemptionMode = RedemptionMode.EnterCode;
  }

  goToAuthenticateAndApprove() {
    this.redemptionMode = RedemptionMode.AuthenticateAndApprove;
  }

  goToConfirmation() {
    this.redemptionMode = RedemptionMode.Confirmation
  }

}

export enum RedemptionMode {
  EnterCode = 'Enter Code',
  AuthenticateAndApprove = 'Authenticate & Approve',
  Confirmation = 'Confirmation'
}