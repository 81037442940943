<form (ngSubmit)="onSubmit()" [formGroup]="authCodeForm">
  <div class="row mt-3">
    <div class="form-group row center-div">
      <label class="col-3 col-form-label">Enter Authorization Code</label>
      <div class="col-9">
        <input type="text" name="authCode" id="authCode"  class="form-control"  formControlName="authCode"
          placeholder="Numeric Only" [ngClass]="{ 'is-invalid': submitted && authCodeForm.invalid }" required />
          @if (submitted && authCodeForm.invalid) {
            <div class="invalid-feedback">
              <p>Authorization Code is required</p>
            </div>
          }
        </div>
      </div>

      <div class="row mt-3 center-div">
        <div class="col text-center">
          <button type="submit" mat-flat-button color="primary">Validate</button>
          <section class="pt-2">
            @if (loading) {
              <div>
                <p>Checking your authorization code</p>
                <div class="spinner-border" role="status">
                </div>
              </div>
            }
            @if (error) {
              <div>
                <p class="error">There was an issue processing this authorization</p>
              </div>
            }
          </section>
        </div>

      </div>
    </div>
  </form>