<div class="container my-3">
  <div class="row mx-auto" id="page-styling">
    <div class="col mode-style" [ngStyle]="{'background-color': redemptionMode === 'Enter Code' ? '#c9deff' : '#f5f5f5'}">
      Enter Code
    </div>
    <div class="col mode-style"
      [ngStyle]="{'background-color': redemptionMode === 'Authenticate & Approve' ? '#c9deff' : '#f5f5f5'}">
      Authenticate & Approve
    </div>
    <div class="col mode-style" [ngStyle]="{'background-color': redemptionMode === 'Confirmation' ? '#c9deff' : '#f5f5f5'}">
      Confirmation
    </div>
  </div>
  @if (redemptionMode === 'Enter Code') {
    <div class="row mx-auto">
      <app-enter-code (confirmation)="goToConfirmation()" (validation)="goToAuthenticateAndApprove()"></app-enter-code>
    </div>
  }
  @if (redemptionMode === 'Authenticate & Approve') {
    <div class="row mx-auto">
      <app-auth-approve (confirmation)="goToConfirmation()" (enterCode)="goToEnterCode()"></app-auth-approve>
    </div>
  }
  @if (redemptionMode === 'Confirmation') {
    <div class="row mx-auto">
      <app-confirmation (enterCode)="goToEnterCode()" ></app-confirmation>
    </div>
  }
</div>