import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { jsPDF } from "jspdf";
import { Router } from '@angular/router';
import { Confirmation } from '../../shared/models/confirmation.model'
import { AuthorizationService } from 'src/app/authorization.service';
import { Authorization } from 'src/app/shared/models/authorization.model';
import { ApiService } from 'src/app/services/api.service';
import { ReverseApprovalModalComponent } from '../reverse-approval-modal/reverse-approval-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

	printedFile: boolean;
  authorization: Authorization;
  hideReverseApproval: boolean = true;

  @Output() enterCode = new EventEmitter<boolean>();

  constructor(
    private readonly router: Router,
    private readonly authorizationService: AuthorizationService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.authorization = this.authorizationService.getAuthorization();
  }

  printConfirmation(){

    const doc = new jsPDF('p', 'pt', 'a4');
    const authInfo = this.authorization?.firstName + '_' + this.authorization?.lastName + '_' + this.authorization?.approvalDate;
    const nameString = 'GM_'+ authInfo + '.pdf';
    // let content = <HTMLElement>document.getElementById('content');
    doc.html(<HTMLElement>document.querySelector('#content'), {
      callback: function (doc) {
        doc.save(nameString);
      },

      x:35,
      y:35,
      margin:[60,60,60,60]
    });

    this.printedFile = true;
  }

  onValidateAnother(): void {
    this.enterCode.emit();
    this.router.navigate(['/redemption']);
  }

  onReverseApproval(authNumber: string): void {
      const dialogRef = this.dialog.open(ReverseApprovalModalComponent, {
        width: '80%',
        data: {}
      });
      dialogRef.componentInstance.authorization = this.authorization;
      dialogRef.componentInstance.close.subscribe((result: { returnAuthorization: Authorization, hideReverseApproval: boolean }) => {
        this.authorization = result.returnAuthorization;
        this.hideReverseApproval = result.hideReverseApproval;
        this.authorizationService.setAuthorization(this.authorization);
      });
    }
  }
