import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './login/login.component';
import { RedemptionComponent } from './redemption/redemption.component';
import { ApprovalComponent } from './approval/approval.component';

import { ConfirmationComponent } from './redemption/confirmation/confirmation.component';
import { LoginReturnComponent } from './login-return/login-return.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' }
  },
  {
    path: 'login-return',
    component: LoginReturnComponent,
    data: { title: 'LoginReturn' }
  },
  {
    path: 'redemption',
    component: RedemptionComponent,
    data: { title: 'redemption' },
    canActivate: [AuthGuard]
  },
  {
    path: 'approval',
    component: ApprovalComponent,
    data: { title: 'approval' },
    canActivate: [AuthGuard]
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    data: { title: 'confirmation' },
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
