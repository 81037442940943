<div class="container">
  <div class="row">
    <div class="col">
      <h4>
        Are you sure you want to reverse the approval?
      </h4>
    </div>
  </div>
<div class="d-flex justify-content-center">
  <div class="btn-toolbar">
    <button mat-flat-button color="primary" (click)="reverseApproval()">Yes</button>
    <div class="div-styling"></div>
    <button mat-flat-button color="primary" (click)="returnToConfirmation()">No</button>
  </div>
</div>

</div>