import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
    isRememberMe: new UntypedFormControl(false),
  });

  constructor(private readonly router: Router,
    private readonly api: ApiService,
    private readonly authService: AuthenticationService) { }

  login(): void {
    this.authService.startLogin();
  }

  ngOnInit(): void { }
}
