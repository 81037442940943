import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { mergeMap, map } from 'rxjs/internal/operators';
import { HttpParams } from '@angular/common/http';
import { User } from '../models/user';
import { ApplicationMetaData } from '../models/application.metadata';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private readonly api: ApiService) { }

  startLogin(_window: Window = window) {//This is just for helping us with
    this.api.getApplicationMetaData().subscribe((meta: ApplicationMetaData) => {
      const authServer = meta.authentication.server;
      const clientId = meta.authentication.clientId;
      const scope = meta.authentication.scope;
      const params = new HttpParams()
        .set('response_type', 'code')
        .set('redirect_uri', `${_window.location.protocol}//${_window.location.host}/login-return`)
        .set('scope', scope)
        .set('client_id', clientId)
        .set('state', '1234');
      _window.location.href = `${authServer}/oauth2/v2.0/authorize?${params.toString()}`;
    });
  }

  loginReturn(code: string): Observable<User> {
    return this.api.login(code);
  }

  Logout(_window: Window = window) {
    return this.api.getApplicationMetaData().pipe(mergeMap(meta => {
      const authServer = meta.authentication.server;
      const clientId = meta.authentication.clientId;

      const logoutUrl = `${authServer}/oauth2/v2.0/logout`;
      return this.api.logout().pipe(map(() => {
        return logoutUrl;
      }));
    })).subscribe((logoutUrl: string) => {
      _window.location.href = logoutUrl;
    }, error => {
    });
  }
}
