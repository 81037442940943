import { Injectable } from '@angular/core';
import { Authorization } from './shared/models/authorization.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  authorization: Authorization;

  constructor() { }

  getAuthorization(){
    return this.authorization;
  }

  setAuthorization(auth: Authorization){
    this.authorization = auth;
  }
}
