import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Modifications for all requests
    let newRequest;

    newRequest = request.clone({
      url: `${environment.popApiUrl}/${request.url}`,
      // Modifications for all POST requests}
    });

    if (newRequest.method === 'POST') {
      newRequest = newRequest.clone({
        withCredentials: true, // to allow cookies to go from localhost
        headers: newRequest.headers.set('Content-Type', 'application/json'),
      });
    }

    return next.handle(newRequest).pipe(
      //TODO: this cause a optimization warning when running app
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          void this.router.navigate(['/']).then();
        }
        throw err;
      })
    );
  }
}
