<mat-toolbar>
  <span>Global Redemption Portal</span>
  <span class="spacer"></span>
  @if (user.accountId) {
    <div class="styling-font">
      <span>Welcome {{user.accountId}}!</span>
      <button mat-button (click)="logout()">Logout</button>
    </div>
  }
</mat-toolbar>
