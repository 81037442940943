import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { AuthorizationService } from 'src/app/authorization.service';
import { ApiService } from 'src/app/services/api.service';
import { ApprovalAuthorizationRequest } from 'src/app/shared/models/approval-authorization-request.model';
import { Authorization } from 'src/app/shared/models/authorization.model';

@Component({
  selector: 'app-auth-approve',
  templateUrl: './auth-approve.component.html',
  styleUrls: ['./auth-approve.component.scss']
})
export class AuthApproveComponent implements OnInit {
  componentDestroyed = new Subject();
  pendingApproval: Authorization;
  pendingAuthorizationRequest: ApprovalAuthorizationRequest;

  @Output() enterCode = new EventEmitter<boolean>();
  @Output() confirmation = new EventEmitter<boolean>();

  constructor(
    private readonly apiService: ApiService,
    private readonly authService: AuthorizationService
    ) { }

  ngOnInit(): void {
    this.pendingApproval = this.authService.getAuthorization();
    this.pendingAuthorizationRequest = {
      authorizationCode: this.pendingApproval?.authorizationCode
    }
  }

  cancelApproval(): void {
    this.enterCode.emit();
  }

  postApproval(authCode: ApprovalAuthorizationRequest) {
    this.apiService.postApproval(authCode)
    .pipe(takeUntil(this.componentDestroyed))
    .subscribe((response: Authorization) => {
      this.authService.setAuthorization(response);
      this.confirmation.emit();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(null);
    this.componentDestroyed.unsubscribe();
  }

}
