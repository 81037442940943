<div class="row">
    <h1>Enter Purchaser Information:</h1>
</div>
<div class="row">
    <div class="col" id="form-styling">
        <p>Authorization Code: </p>
    </div>
    <div class="col">
        <p>
            {{pendingApproval.authorizationCode}}
        </p>
    </div>
</div>

<div class="row">
    <div class="col" id="form-styling">
        <p>Authorization Issue Date: </p>
    </div>
    <div class="col">
        <p>
           {{pendingApproval.issueDate | date: 'MM/dd/yyyy'}}
        </p>
    </div>
</div>

<div class="row">
    <div class="col" id="form-styling">
        <p>Authorization Expiration: </p>
    </div>
    <div class="col">
        <p>
            {{pendingApproval.expirationDate | date: 'MM/dd/yyyy'}}
        </p>
    </div>
</div>

<div class="row">
    <div class="col" id="form-styling">
        <p>Authorization Amount: </p>
    </div>
    <div class="col">
        <p>
            {{pendingApproval.authorizationAmount}}
        </p>
    </div>
</div>

<div class="row">
    <div class="col" id="form-styling">
        <p>First Name: </p>
    </div>
    <div class="col">
        <p>
            {{pendingApproval.firstName}}
        </p>
    </div>
</div>

<div class="row">
    <div class="col" id="form-styling">
        <p>Last Name: </p>
    </div>
    <div class="col">
        <p>
            {{pendingApproval.lastName}}
        </p>
    </div>
</div>

<div class="row">
    <div class="col" id="form-styling">
        <p>Postal Code: </p>
    </div>
    <div class="col">
        <p>
            {{pendingApproval.postalCode}}
        </p>
    </div>
</div>

<div class="row">
    <div class="col" id="form-styling">
        <p>Campaign Name: </p>
    </div>
    <div class="col">
        <p>
            {{pendingApproval.campaignName}}
        </p>
    </div>
</div>

<div class="row">
    <div class="col text-center">
        <button mat-flat-button (click)="cancelApproval()" id="cancel-button">Cancel</button>
    </div>
    <div class="col text-center">
        <button type="submit" (click)="postApproval(pendingAuthorizationRequest)" mat-flat-button color="primary">Validate</button>
    </div>
</div>
